import { autoinject, bindable } from 'aurelia-framework';
import { formatNumber } from 'utils/number-format';
import AutoNumeric from 'autonumeric';

@autoinject()
export class FormatNumberCustomAttribute {
    //@bindable format: string;
    element: any;

    autoNumeric: AutoNumeric;

    constructor(element: Element) {
        this.element = element;

        console.log(this.element);

        if (this.element instanceof HTMLElement) {
            this.autoNumeric = new AutoNumeric(this.element, undefined, {
                digitGroupSeparator: ' ',
                decimalPlaces: 0,
            });
            // this.element.oninput = (e: any) => {
            //     //console.log("oninput");
            //     //console.dir(e);

            //     let cursorPos = e.target.selectionStart
            //     let es = e.target.selectionEnd;

            //     console.log(`CursorPos: ${cursorPos}`)

            //     let formatInput = formatNumber(e.target.value);

            //     e.target.value = formatInput;

            //     let isBackspace = (e?.data == null) ? true : false

            //     //console.log("isbackspace: " + isBackspace)
            //     let nextCusPos = this.nextDigit(formatInput, cursorPos, isBackspace)

            //     console.log(`nextCusPos: ${nextCusPos}`)

            //     e.target.setSelectionRange(nextCusPos + 1, nextCusPos + 1);
            // };
        }
    }

    nextDigit(input, cursorpos, isBackspace) {
        console.log('nextDigit Start');
        if (isBackspace) {
            for (let i = cursorpos - 1; i > 0; i--) {
                if (/\d/.test(input[i])) {
                    return i;
                }
            }
        } else {
            for (let i = cursorpos - 1; i < input.length; i++) {
                console.log(input[i]);
                if (/\d/.test(input[i])) {
                    return i;
                }
            }
        }

        return cursorpos;
    }
}
